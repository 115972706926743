import { useState } from "react";
import { EditIngredient, Recipe } from "../../modals";
import { Food } from "../../services/users.service";
import FoodImage from "../FoodImage";
import MacrosInfo from "../MacrosInfo";
import styles from "./styles.module.scss";

type FoodCardProps = {
  food: Food;
};

const FoodCard: React.FC<FoodCardProps> = ({ food }) => {
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isShowIngredientPreview, setIsShowIngredientPreview] = useState(false);
  return (
    <>
      <Recipe
        isOpen={isShowPreview}
        setIsOpen={setIsShowPreview}
        recipeId={food?.foodId}
        withoutActionButtons
        foodAuthor={food?.foodAuthor}
        onGetRecipes={() => {}}
      />
      <EditIngredient
        isOpen={isShowIngredientPreview}
        setIsOpen={setIsShowIngredientPreview}
        onGetIngredients={() => {}}
        ingredientId={food?.foodId}
        // ingredient={}
      />
      <div
        onClick={() => {
          if (food?.foodType === "Recipe") {
            setIsShowPreview(true);
          }
          if (food?.foodType === "Ingredient") {
            setIsShowIngredientPreview(true);
          }
        }}
        className={styles.wrapper}
      >
        <FoodImage image={food?.image} />
        <div className={styles.contentContainer}>
          <p className={styles.title}>
            {food?.name}
            {"  "}
            <span>
              {food?.foodType === "Ingredient" && food?.brand && (
                <>· {food?.brand}</>
              )}
              {food?.foodType === "Recipe" ? (
                <>
                  {food?.count && food?.count !== 1 ? ` (${food?.count})` : ""}
                </>
              ) : food?.foodType !== "Estimate" ? (
                <>
                  ({food?.count} {food?.ingredientUnit})
                </>
              ) : null}
            </span>
          </p>
          <MacrosInfo
            calories={food?.calories}
            protein={food?.proteins}
            carbs={food?.carbs}
            fats={food?.fats}
            fiber={food?.fiber}
            pe={food?.pe}
          />
        </div>
      </div>
    </>
  );
};

export default FoodCard;
