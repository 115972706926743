import { formatNumberToLocaleString } from "../../utils/methods";
import MacroItem from "../MacroItem";
import styles from "./styles.module.scss";

type MacrosItem = {
  calories: number;
  protein: number;
  carbs: number;
  fats: number;
  fiber: number;
  pe?: number;
};

const MacrosInfo: React.FC<MacrosItem> = ({
  calories,
  protein,
  carbs,
  fats,
  fiber,
  pe,
}) => {
  return (
    <div className={styles.wrapper}>
      <MacroItem
        title={"cal"}
        value={formatNumberToLocaleString(calories, 0)}
      />
      <MacroItem
        title={"Prot"}
        value={formatNumberToLocaleString(protein, 0)}
      />
      <MacroItem title={"Fat"} value={formatNumberToLocaleString(fats, 0)} />
      <MacroItem title={"Carb"} value={formatNumberToLocaleString(carbs, 0)} />
      <MacroItem title={"Fiber"} value={formatNumberToLocaleString(fiber, 0)} />
      {pe ? (
        <MacroItem title={"P%"} value={formatNumberToLocaleString(pe, 0)} />
      ) : null}
    </div>
  );
};

export default MacrosInfo;
