import dayjs, { Dayjs } from "dayjs";
import styles from "./styles.module.scss";
import { EACTIVITY_LEVEL, IUser } from "../../models";
import {
  getUserCalorieBurn,
  MealIntervalLog,
} from "../../services/users.service";
import { useQuery } from "@tanstack/react-query";
import { getDaysOfWeek } from "../../utils";
import { FC, useMemo } from "react";
import { formatNumberToLocaleString } from "../../utils/methods";
import {
  Bar,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLabelStyleObject,
  VictoryPortal,
  VictoryScatter,
  VictoryStack,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { COLORS } from "../../constants";
import React from "react";
import { BarPathComponent, Legend } from "../../components";
import { TriangleIcon } from "../../assets";

type CalorieBurnProps = {
  date: Dayjs;
  user: IUser;
  selectedWeekMealInterval?: MealIntervalLog[];
};

type LabelPosition = "top" | "center" | "bottom";

interface LabelProps {
  position?: LabelPosition;
  overlapPosition?: "top" | "bottom" | "center" | "hidden";
  labelStyle?: VictoryLabelStyleObject;
  height?: number;
  maxValue: number;
  overlapLabelColor?: string;
  extraDy?: number;
  datum?: {
    y: number;
  };
  data?: Array<{
    y: number;
    datum: {
      _y1: number;
      y: number;
    };
  }>;
}

// @ts-ignore
const Label: React.FC<LabelProps> = ({
  position = "center",
  overlapPosition = "center",
  labelStyle = {},
  overlapLabelColor = COLORS.textBlack,
  height = 0,
  extraDy = 0,
  data,
  maxValue,
  ...props
}) => {
  if (props?.datum?.y && maxValue) {
    const dy = ((height / maxValue) * props.datum.y) / 2;
    const labelOverflowed = Math.abs(dy) < 7;

    if (labelOverflowed && overlapPosition === "hidden") {
      return <></>;
    }

    return (
      <VictoryLabel
        {...props}
        style={{
          fontSize: 10,
          fontFamily: "Inter",
          fontWeight: "600",
          fill: labelOverflowed ? overlapLabelColor : COLORS.textBlack,
          ...labelStyle,
        }}
        dy={(data) => {
          if (labelOverflowed) {
            if (overlapPosition === "bottom") {
              return dy + 15 + extraDy;
            } else {
              return dy - 15 - extraDy;
            }
          }
          return (((height - 30) / maxValue) * data.datum.y) / 2;
        }}
        verticalAnchor="middle"
      />
    );
  }
};

const CalorieBurn: React.FC<CalorieBurnProps> = ({
  user,
  date,
  selectedWeekMealInterval,
}) => {
  const from = date.startOf("isoWeek");
  const to = date.endOf("isoWeek");

  const { data: trackerData } = useQuery({
    queryKey: ["calorie-burn", user?.id, from, to],
    queryFn: async () => {
      return await getUserCalorieBurn(
        user?.id || 0,
        from?.toISOString()?.split("T")[0],
        to?.toISOString()?.split("T")[0]
      );
    },
    enabled: !!user?.tracker,
  });

  const weekDays = getDaysOfWeek(date.toDate());

  const chartData = useMemo(() => {
    return weekDays.map((date) => {
      const dailyData = selectedWeekMealInterval?.find(
        (item) => item?.date === date
      );
      return {
        date: date?.toString(),
        BMR: +(dailyData?.loggedValues?.BMR?.toFixed(0) || 0),
        NEAT: +(dailyData?.loggedValues?.NEAT?.toFixed(0) || 0),
        exercise: +(dailyData?.loggedValues?.activityCalories?.toFixed(0) || 0),
        total: +(
          (dailyData?.loggedValues?.BMR || 0) +
          (dailyData?.loggedValues?.NEAT || 0) +
          (dailyData?.loggedValues?.activityCalories || 0)
        )?.toFixed(0),
        manualDataUsed: dailyData?.manualDataUsed,
        trackerType: dailyData?.trackerType,
      };
    });
  }, [user, trackerData, selectedWeekMealInterval, weekDays]);

  const totalBurned = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, cur) => {
        return (
          acc +
          (cur?.loggedValues?.BMR || 0) +
          (cur?.loggedValues?.NEAT || 0) +
          (cur?.loggedValues?.activityCalories || 0)
        );
      }, 0) || 0
    );
  }, [user, selectedWeekMealInterval]);

  const totalExercise = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, item) => {
        return acc + item.loggedValues.activityCalories;
      }, 0) || 0
    );
  }, [selectedWeekMealInterval]);

  const totalManual = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, item) => {
        return (
          acc + (item?.loggedValues?.BMR || 0) + (item?.loggedValues?.NEAT || 0)
        );
      }, 0) || 0
    );
  }, [selectedWeekMealInterval]);

  const percentExercise = useMemo(() => {
    return (totalExercise / totalBurned) * 100;
  }, [totalExercise, totalBurned]);

  const percentManual = useMemo(() => {
    return (totalManual / totalBurned) * 100;
  }, [totalManual, totalBurned]);

  const maxValue = chartData?.reduce((acc, item) => {
    return Math.max(acc, item.BMR + item.NEAT + item.exercise);
  }, 0);

  const manualData = useMemo(() => {
    return chartData.map((item) =>
      item.manualDataUsed
        ? {
            x: item.date,
            y: 11,
            y0: 10,
            label: item.exercise
              ? `Your tracker did not provide accurate 
              data for this day, so we have replaced 
              it with our calorie burn estimation. Your 
              exercise has not been replaced.`
              : `Your tracker did not provide accurate 
              data for this day, so we have replaced 
              it with our calorie burn estimation.`,
          }
        : { x: item.date, y: 0 }
    );
  }, [chartData]);
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Calories Out Data</p>
      <p className={styles.infoTitle}>
        Method: <span>{user?.tracker ? "Tracker" : "Manual"}</span>
      </p>
      {user?.anotherTrackers?.length ? (
        <p className={styles.infoTitle}>
          Alt Tracker: <span>{user?.anotherTrackers?.join(", ")}</span>
        </p>
      ) : null}
      {user?.howOftenWearsTracker ? (
        <p className={styles.infoTitle}>
          How often wears tracker: <span>{user?.howOftenWearsTracker}</span>
        </p>
      ) : null}
      <p className={styles.infoTitle}>
        Lifestyle:{" "}
        <span>
          {EACTIVITY_LEVEL.SEDENTARY === user?.neatFactor
            ? 1
            : EACTIVITY_LEVEL.LIGHTLY_ACTIVE === user?.neatFactor
            ? 2
            : EACTIVITY_LEVEL.MODERATELY_ACTIVE === user?.neatFactor
            ? 3
            : EACTIVITY_LEVEL.VERY_ACTIVE === user?.neatFactor
            ? 4
            : 5}
          {"-"}
          {user?.neatFactor}
        </span>
      </p>

      <div className={styles.chartContainer}>
        <div className={styles.headerValues}>
          <div className={styles.block}>
            <p className={styles.weight}>
              {formatNumberToLocaleString(totalBurned || 0)} <span>cal</span>
            </p>

            <p className={styles.title}>
              TOTAL CALORIES OUT OVER <br /> THE WEEK
            </p>
          </div>
        </div>

        <VictoryChart
          maxDomain={{ y: maxValue === 0 ? 600 : maxValue + maxValue * 0.2 }}
          padding={{ left: 35, top: 15, bottom: 15, right: 0 }}
          domainPadding={{ x: [60, 60] }}
          width={429}
          height={228}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            style={{
              tickLabels: {
                fontSize: 9,
                padding: 4,
                fill: COLORS.darkGray,
              },
              grid: { stroke: COLORS.lightGray },
              axis: { stroke: "transparent" },
            }}
          />
          <VictoryAxis
            dependentAxis={false}
            tickFormat={() => {
              return "";
            }}
            style={{
              axis: {
                stroke: COLORS.lightGray,
              },
            }}
          />

          <VictoryScatter
            labelComponent={
              <VictoryTooltip
                cornerRadius={10}
                flyoutWidth={205}
                flyoutPadding={{
                  top: 4,
                  left: 10,
                  bottom: 4,
                  right: 4,
                }}
                centerOffset={{
                  x: 0,
                  y: 70,
                }}
                lineHeight={2}
                flyoutHeight={50}
                pointerOrientation={"top"}
                pointerWidth={15}
                flyoutStyle={{
                  fill: "rgba(30, 38, 50, 1)",
                }}
                style={{
                  fontFamily: "Inter",
                  fontSize: 10,
                  strokeWidth: 0.5,
                  fill: "rgba(230, 230, 230, 1)",
                }}
              />
            }
            dataComponent={<InfoIcon />}
            data={manualData}
            name="info-icon"
          />
          {chartData?.map((item, index) => {
            const isExerciseLabelOverlap =
              item.exercise &&
              Math.abs(((229 / maxValue) * item.exercise) / 2) < 7;

            const isNeatLabelOverlap =
              item.NEAT && Math.abs(((229 / maxValue) * item.NEAT) / 2) < 7;

            const exerciseDy = ((229 / maxValue) * item.exercise) / 2;
            return (
              <VictoryGroup key={index}>
                <VictoryScatter
                  dataComponent={<></>}
                  labelComponent={
                    <VictoryLabel
                      dy={
                        isExerciseLabelOverlap && isNeatLabelOverlap
                          ? -31
                          : isExerciseLabelOverlap || isNeatLabelOverlap
                          ? -20
                          : -10
                      }
                      style={{
                        fontSize: 10,
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    />
                  }
                  labels={(props) => {
                    if (props?.datum?.total && props.datum.y > 1) {
                      return props.datum.y;
                    }
                  }}
                  data={[
                    {
                      x: item.date,
                      y: item.total,
                      y0: item.total,
                      total: true,
                    },
                  ]}
                />

                <VictoryStack>
                  <VictoryBar
                    barWidth={32}
                    style={{
                      data: {
                        fill: ({ datum }) =>
                          datum.manualDataUsed
                            ? COLORS.purpleOpacity20
                            : COLORS.orangeOpacity20,
                        stroke: COLORS.orange,
                        strokeWidth: (data) => {
                          return data?.datum?.y ? 3 : 0;
                        },
                      },
                    }}
                    labels={({ datum }) => datum.y}
                    labelComponent={
                      <Label
                        maxValue={maxValue}
                        // labelStyle={styles.label}
                        overlapPosition={"hidden"}
                      />
                    }
                    dataComponent={
                      <Bar
                        pathComponent={React.createElement(BarPathComponent)}
                      />
                    }
                    cornerRadius={{
                      top: () => 3,
                    }}
                    data={[
                      {
                        x: item.date,
                        y: item.BMR,
                        manualDataUsed: item.manualDataUsed,
                      },
                    ]}
                  />
                  <VictoryBar
                    barWidth={32}
                    style={{
                      data: {
                        fill: ({ datum }) =>
                          datum.manualDataUsed
                            ? COLORS.purpleOpacity20
                            : COLORS.brownOpacity20,
                        stroke: COLORS.brown,
                        strokeWidth: (data) => {
                          return data?.datum?.y ? 3 : 0;
                        },
                      },
                    }}
                    labels={({ datum }) => datum.y}
                    labelComponent={
                      <Label
                        maxValue={maxValue}
                        overlapLabelColor={COLORS.brown}
                        extraDy={exerciseDy + 4}
                      />
                    }
                    dataComponent={
                      <Bar
                        pathComponent={React.createElement(BarPathComponent)}
                      />
                    }
                    cornerRadius={{
                      top: () => 3,
                    }}
                    data={[
                      {
                        x: item.date,
                        y: item.NEAT,
                        y0: item.BMR,
                        manualDataUsed: item.manualDataUsed,
                      },
                    ]}
                  />

                  <VictoryBar
                    barWidth={32}
                    style={{
                      data: {
                        fill: COLORS.springGreenOpacity20,
                        stroke: COLORS.springGreen,
                        strokeWidth: (data) => {
                          return data?.datum?.y ? 3 : 0;
                        },
                      },
                    }}
                    labels={({ datum }) => {
                      return datum.y;
                    }}
                    labelComponent={
                      <Label
                        maxValue={maxValue}
                        overlapLabelColor={COLORS.springGreen}
                        extraDy={isNeatLabelOverlap ? 11 : 0}
                      />
                    }
                    dataComponent={
                      <Bar
                        pathComponent={React.createElement(BarPathComponent)}
                      />
                    }
                    cornerRadius={{
                      top: () => 3,
                    }}
                    data={[
                      {
                        x: item.date,
                        y: item.exercise,
                        y0: item.BMR + item.NEAT,
                        manualDataUsed: item.manualDataUsed,
                      },
                    ]}
                  />

                  <VictoryScatter
                    dataComponent={
                      <TrackerIcon trackerType={item.trackerType} />
                    }
                    data={[
                      {
                        x: item.date,
                        total: item.total,
                        manualDataUsed: item.manualDataUsed,
                        y: item.trackerType && !item.manualDataUsed ? 11 : 0,
                        y0: item.trackerType && !item.manualDataUsed ? 10 : 0,
                      },
                    ]}
                  />
                </VictoryStack>
              </VictoryGroup>
            );
          })}
        </VictoryChart>
        <div className={styles.xAxisContainer}>
          {chartData?.map((item, index) => {
            return (
              <p key={index} className={styles.xAxisText}>
                {dayjs.utc(item.date).format("MMM DD")}
              </p>
            );
          })}
        </div>
        <div className={styles.footerChart}>
          <Legend
            label={"BMR"}
            description="Calories burn when your intake is less than your caloric expenditure."
            colorOpacity={COLORS.orange}
            color={COLORS.orange}
            variant="border"
          />

          <Legend
            label={"NEAT"}
            description="Your calorie deficit goal."
            color={COLORS.brown}
            colorOpacity={COLORS.brown}
          />
          <Legend
            label={"Exercise"}
            description="Your calorie deficit goal."
            color={COLORS.springGreen}
            colorOpacity={COLORS.springGreen}
          />
          <Legend
            label={"Manual"}
            description="Your calorie deficit goal."
            color={COLORS.purpleOpacity20}
            colorOpacity={COLORS.purpleOpacity20}
          />
        </div>

        <div className={styles.headerValues}>
          <div className={styles.block}>
            <p className={styles.weight}>
              {formatNumberToLocaleString(totalManual)} <span>cal</span>
            </p>

            <p className={styles.title}>
              <div
                style={{
                  borderColor: COLORS.orange,
                  backgroundColor: COLORS.orangeOpacity20,
                }}
                className={styles.indicator}
              />
              Total non-exercise calories <br /> out for the week
            </p>
            <p className={styles.title}>
              <span style={{ color: COLORS.orange, fontWeight: 600 }}>
                {formatNumberToLocaleString(percentManual)}%
              </span>{" "}
              of total calories
            </p>
          </div>
          <div className={styles.block}>
            <p className={styles.weight}>
              {formatNumberToLocaleString(totalExercise)} <span>cal</span>
            </p>

            <p className={styles.title}>
              <div
                style={{
                  borderColor: COLORS.springGreen,
                  backgroundColor: COLORS.springGreenOpacity20,
                }}
                className={styles.indicator}
              />
              TOTAL EXERCISE CALORIES <br /> OUT FOR THE WEEK
            </p>
            <p className={styles.title}>
              <span style={{ color: COLORS.springGreen, fontWeight: 600 }}>
                {formatNumberToLocaleString(percentExercise)}%
              </span>{" "}
              of total calories
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoIcon: any = (props: any) => {
  if (props?.datum?.y > 0) {
    return (
      <svg
        x={props.x - 10}
        y={props.y - 25}
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          d="M10.5 1.75C8.76942 1.75 7.07769 2.26318 5.63876 3.22464C4.19983 4.1861 3.07832 5.55267 2.41606 7.15152C1.75379 8.75037 1.58051 10.5097 1.91813 12.207C2.25575 13.9044 3.08911 15.4635 4.31282 16.6872C5.53653 17.9109 7.09563 18.7443 8.79296 19.0819C10.4903 19.4195 12.2496 19.2462 13.8485 18.5839C15.4473 17.9217 16.8139 16.8002 17.7754 15.3612C18.7368 13.9223 19.25 12.2306 19.25 10.5C19.2474 8.18015 18.3247 5.95605 16.6843 4.31567C15.044 2.67528 12.8199 1.75258 10.5 1.75ZM10.5 17.5C9.11553 17.5 7.76216 17.0895 6.61101 16.3203C5.45987 15.5511 4.56266 14.4579 4.03285 13.1788C3.50303 11.8997 3.36441 10.4922 3.63451 9.13437C3.9046 7.7765 4.57129 6.52922 5.55026 5.55025C6.52922 4.57129 7.7765 3.9046 9.13437 3.6345C10.4922 3.36441 11.8997 3.50303 13.1788 4.03284C14.4579 4.56266 15.5511 5.45986 16.3203 6.61101C17.0895 7.76215 17.5 9.11553 17.5 10.5C17.4979 12.3559 16.7597 14.1351 15.4474 15.4474C14.1351 16.7597 12.3559 17.4979 10.5 17.5ZM10.5 10.0625C10.2679 10.0625 10.0454 10.1547 9.88129 10.3188C9.71719 10.4829 9.625 10.7054 9.625 10.9375V13.5625C9.625 13.7946 9.71719 14.0171 9.88129 14.1812C10.0454 14.3453 10.2679 14.4375 10.5 14.4375C10.7321 14.4375 10.9546 14.3453 11.1187 14.1812C11.2828 14.0171 11.375 13.7946 11.375 13.5625V10.9375C11.375 10.7054 11.2828 10.4829 11.1187 10.3188C10.9546 10.1547 10.7321 10.0625 10.5 10.0625ZM10.5 6.5625C10.2837 6.5625 10.0722 6.62665 9.89235 6.74683C9.71248 6.86701 9.57229 7.03783 9.48951 7.23769C9.40673 7.43755 9.38507 7.65746 9.42727 7.86963C9.46947 8.0818 9.57364 8.27668 9.72661 8.42965C9.87957 8.58261 10.0745 8.68678 10.2866 8.72898C10.4988 8.77119 10.7187 8.74953 10.9186 8.66674C11.1184 8.58396 11.2892 8.44377 11.4094 8.26391C11.5296 8.08404 11.5938 7.87257 11.5938 7.65625C11.5938 7.36617 11.4785 7.08797 11.2734 6.88285C11.0683 6.67773 10.7901 6.5625 10.5 6.5625Z"
          fill="#612AFF"
        />
      </svg>
    );
  }
};

const TrackerIcon: any = ({ trackerType, ...props }: any) => {
  if (!props?.datum?.total || props?.datum?.manualDataUsed) {
    return null;
  }
  if (trackerType === "apple_health_kit" || trackerType === "Apple Health") {
    return (
      <svg
        x={props.x - 8}
        y={props.y - 22}
        width="16"
        height="16"
        viewBox="0 0 24 24"
      >
        <path
          d="M15.6132 3.82775C16.0776 3.30492 16.4316 2.69763 16.655 2.04075C16.8785 1.38387 16.9669 0.690347 16.9152 0C15.4897 0.112417 14.1664 0.767416 13.2305 1.82388C12.7823 2.33022 12.4427 2.91926 12.2317 3.55614C12.0208 4.19301 11.9427 4.86477 12.0023 5.53163C12.6976 5.53729 13.385 5.38656 14.0112 5.09109C14.6373 4.79563 15.1855 4.36336 15.6132 3.82775ZM18.7084 12.7552C18.7166 11.8357 18.9645 10.9335 19.4287 10.1336C19.8929 9.33368 20.558 8.66253 21.3613 8.18346C20.8543 7.46979 20.1841 6.88134 19.4038 6.46478C18.6235 6.04822 17.7546 5.815 16.866 5.78362C14.95 5.59163 13.1814 6.87554 12.1619 6.87554C11.1425 6.87554 9.7055 5.80761 8.10882 5.83161C7.06499 5.86521 6.04789 6.16253 5.15672 6.69458C4.26556 7.22663 3.53075 7.97525 3.02398 8.86741C0.862311 12.5392 2.47128 17.9988 4.63295 20.9626C5.61553 22.4145 6.84375 24.0584 8.465 23.9984C10.0863 23.9384 10.6144 23.0145 12.4936 23.0145C14.3727 23.0145 14.95 23.9984 16.5467 23.9624C18.1434 23.9264 19.2733 22.4745 20.3051 21.0226C21.0359 19.9685 21.6068 18.8167 22 17.6028C21.0266 17.1973 20.1961 16.522 19.6112 15.6605C19.0262 14.799 18.7123 13.789 18.7084 12.7552Z"
          fill="#1E2632"
        />
      </svg>
    );
  }

  if (trackerType === "fitbit" || trackerType === "Fitbit") {
    return (
      <svg
        x={props.x - 8}
        y={props.y - 22}
        width="16"
        height="16"
        viewBox="0 0 24 24"
      >
        <path
          d="M13.298 1.825c0 .976-.81 1.785-1.786 1.785-.972 0-1.784-.81-1.784-1.785 0-.973.813-1.785 1.784-1.785.976 0 1.786.813 1.786 1.785zm-1.786 3.243c-1.052 0-1.863.81-1.863 1.866 0 1.053.81 1.865 1.865 1.865 1.053 0 1.865-.811 1.865-1.865s-.825-1.866-1.875-1.866h.008zm0 5.029c-1.052 0-1.945.891-1.945 1.945s.894 1.945 1.947 1.945 1.946-.891 1.946-1.945-.894-1.945-1.946-1.945h-.002zm0 5.107c-1.052 0-1.863.81-1.863 1.864s.81 1.866 1.865 1.866c1.053 0 1.865-.811 1.865-1.866 0-.972-.825-1.864-1.875-1.864h.008zm0 5.191c-.972 0-1.784.809-1.784 1.784 0 .97.813 1.781 1.784 1.781.977 0 1.786-.809 1.786-1.784 0-.973-.81-1.781-1.786-1.781zM16.46 4.823c-1.136 0-2.108.977-2.108 2.111 0 1.134.973 2.107 2.108 2.107 1.135 0 2.106-.975 2.106-2.107 0-1.135-.972-2.109-2.106-2.109v-.002zm0 5.03c-1.216 0-2.19.973-2.19 2.19 0 1.216.975 2.187 2.19 2.187 1.215 0 2.189-.971 2.189-2.189 0-1.216-.974-2.188-2.189-2.188zm0 5.108c-1.136 0-2.108.976-2.108 2.107 0 1.135.973 2.109 2.108 2.109 1.135 0 2.106-.976 2.106-2.109s-.971-2.107-2.106-2.107zm5.106-5.353c-1.296 0-2.43 1.055-2.43 2.434 0 1.297 1.051 2.433 2.43 2.433 1.381 0 2.434-1.065 2.434-2.444-.082-1.382-1.135-2.431-2.434-2.431v.008zM6.486 5.312c-.892 0-1.62.73-1.62 1.623 0 .891.729 1.62 1.62 1.62.893 0 1.619-.729 1.619-1.62 0-.893-.727-1.62-1.619-1.62v-.003zm0 5.027c-.973 0-1.703.729-1.703 1.703 0 .975.721 1.703 1.695 1.703s1.695-.73 1.695-1.703c0-.975-.735-1.703-1.71-1.703h.023zm0 5.107c-.892 0-1.62.731-1.62 1.62 0 .895.729 1.623 1.62 1.623.893 0 1.619-.735 1.619-1.635s-.727-1.62-1.619-1.62v.012zm-5.025-4.863c-.813 0-1.461.646-1.461 1.459 0 .81.648 1.459 1.46 1.459.81 0 1.459-.648 1.459-1.459s-.648-1.459-1.458-1.459z"
          fill="#1E2632"
        />
      </svg>
    );
  }
};

export default CalorieBurn;
