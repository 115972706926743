import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getReports = (
  value: string,
  page: number,
  limit: number,
  categories: string[],
  types: string[],
  sortValue: string | null,
  order: number | null
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let body: any = {
      page,
      limit,
    };
    if (value) {
      body.value = value;
    }
    if (categories?.length) {
      body.categories = categories.join("|");
    }
    if (types?.length) {
      body.types = types.join("|");
    }

    if (sortValue) {
      body.sortValue = sortValue;
    }

    if (order) {
      body.order = order;
    }
    axiosInstance
      .get("reports/search", { params: body })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteReport = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`reports/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
