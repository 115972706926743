import { useMemo } from "react";
import { CheckIcon, PlannerIcon } from "../../assets";
import { MealIntervalSection } from "../../services/users.service";
import FoodCard from "../FoodCard";
import LoggedContainer from "../LoggedContainer";
import styles from "./styles.module.scss";
import { formatNumberToLocaleString } from "../../utils/methods";

type MealSectionProps = {
  section: MealIntervalSection;
};

const MealSection: React.FC<MealSectionProps> = ({ section }) => {
  const foodsCounter = useMemo(() => {
    return section?.foods?.reduce(
      (acc, cur) => {
        if (cur?.isLogged) {
          return { ...acc, logged: acc.logged + 1 };
        } else {
          return { ...acc, planned: acc.planned + 1 };
        }
      },
      {
        logged: 0,
        planned: 0,
      }
    );
  }, [section]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.infoContainer}>
          <p>{section.name}</p>
          <p className={styles.macros}>
            {formatNumberToLocaleString(section.calories, 0)} <span>cal</span> ·{" "}
            {formatNumberToLocaleString(section.proteins, 0)} <span>p</span> ·{" "}
            {formatNumberToLocaleString(section.fats, 0)} <span>fat</span> ·{" "}
            {formatNumberToLocaleString(section.carbs, 0)} <span>c</span> ·{" "}
            {formatNumberToLocaleString(section.fiber, 0)} <span>fib</span> ·{" "}
            {formatNumberToLocaleString(section.pe, 0)}% <span>P%</span>
          </p>
        </div>
        <div className={styles.countersContainer}>
          <div className={styles.counter}>
            <span>{foodsCounter.logged}</span>
            <div
              style={{
                backgroundColor:
                  foodsCounter.logged === 0
                    ? "rgba(217, 224, 233, 1)"
                    : "rgba(54, 217, 14, 1)",
              }}
              className={styles.iconContainer}
            >
              <CheckIcon />
            </div>
          </div>
          <div className={styles.counter}>
            <span>{foodsCounter.planned}</span>
            <div
              style={{
                backgroundColor:
                  foodsCounter.planned === 0
                    ? "rgba(217, 224, 233, 1)"
                    : "rgba(97, 42, 255, 1)",
              }}
              className={styles.iconContainer}
            >
              <PlannerIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.foodsContainer}>
        {section?.foods?.map((food, i) => {
          const listLength = section.foods.length;
          const order =
            listLength === 1
              ? "single"
              : i === 0
              ? "first"
              : i === listLength - 1
              ? "last"
              : "middle";

          return (
            <div>
              <LoggedContainer
                order={order}
                status={food?.isLogged ? "logged" : "planned"}
              >
                <FoodCard food={food} />
              </LoggedContainer>
              {i !== section?.foods?.length - 1 ? (
                <div
                  style={{
                    backgroundColor:
                      section.foods?.[i + 1]?.isLogged || food?.isLogged
                        ? "rgba(54, 217, 14, 1)"
                        : "rgba(245, 247, 250, 1)",
                  }}
                  className={styles.foodDivider}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MealSection;
