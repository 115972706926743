import { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Logo from "../../assets/icons/logo.svg";
import {
  CandidatesIcon,
  FaqIcon,
  FeedbackIcon,
  LeaderBoardIcon,
  LogoutIcon,
  RecipesIcon,
  ReportIcon,
  StaffIcon,
  StatisticsIcon,
} from "../../assets";
import { NavLink, useNavigate } from "react-router-dom";
import SidebarDropdown from "../SidebarDropdown";
import clsx from "clsx";

import PlaceholderAvatar from "../../assets/images/avatar.png";
import { motion } from "framer-motion";
import { useOutsideClick } from "../../hooks";
import {
  fetchMe,
  getUser,
  removeAccessToken,
  removeRefreshToken,
} from "../../utils/tokenStorage";
import { requestFeedback } from "../../services/feedback.service";
import { useQuery } from "@tanstack/react-query";

const Sidebar: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [me, setMe] = useState(getUser());

  const { data } = useQuery({
    queryKey: ["feedback", 0],
    queryFn: async () => {
      return await requestFeedback({
        page: 0,
        limit: 20,
      });
    },
  });

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onLogOut = () => {
    removeAccessToken();
    removeRefreshToken();
    navigate("/signin");
  };

  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    function storageEventHandler(event: any) {
      setMe(getUser());
    }

    window.addEventListener("storage", storageEventHandler);

    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonsContainer}>
        <img src={Logo} alt={"logo"} className={styles.logo} />

        {/* <NavLink to={"/statistics"} className={styles.linkBtn}>
          <StatisticsIcon />
          <p>Statistics</p>
        </NavLink> */}
        <NavLink to={"/users?page=1"} className={styles.linkBtn}>
          <CandidatesIcon />
          <p>Users</p>
        </NavLink>
        <NavLink to={"/feedback?page=1"} className={styles.linkBtn}>
          <FeedbackIcon />
          <p>Feedback</p>
          {data && data?.unreviewed > 0 ? (
            <div className={styles.unreadIndicator} />
          ) : null}
        </NavLink>

        <SidebarDropdown icon={<RecipesIcon />} title={"Meals"}>
          <NavLink
            to={"/all-recipes"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>All Meals</p>
          </NavLink>
          <NavLink
            to={"/ingredients"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>Ingredients</p>
          </NavLink>
          <NavLink
            to={"/categories"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>Categories</p>
          </NavLink>
          <NavLink
            to={"/tags"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>Tags</p>
          </NavLink>
        </SidebarDropdown>
        {/* <NavLink to={"/reports"} className={styles.linkBtn}>
          <ReportIcon />
          <p>Reports</p>
        </NavLink> */}
        <NavLink to={"/faq"} className={styles.linkBtn}>
          <FaqIcon />
          <p>FAQ</p>
        </NavLink>
        {me?.type === "Admin" && (
          <NavLink to={"/staff-management"} className={styles.linkBtn}>
            <StaffIcon />
            <p>Staff Management</p>
          </NavLink>
        )}
      </div>
      <div
        ref={ref}
        onClick={() => setIsOpenMenu((prev) => !prev)}
        className={styles.profileContainer}
      >
        <img
          src={me?.image || PlaceholderAvatar}
          alt={"avatar"}
          className={styles.avatar}
        />
        <div className={styles.infoContainer}>
          <p className={styles.name}>{me?.fullName}</p>
          <p className={styles.role}>
            {me?.type === "Admin" ? "Super Admin" : me?.type}
          </p>
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menu}
          >
            <div onClick={onLogOut} className={styles.logoutBtn}>
              <LogoutIcon />
              Logout
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
export default Sidebar;
