import { id } from "date-fns/locale";
import axiosInstance from "../apis";
import { BASE_URL_V2 } from "../constants";

export type FeedbackItem = {
  appVersion: string;
  area: string;
  createdAt: string;
  feedback: string;
  image: string | null;
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  rating: "1" | "2" | "3";
  reviewed: null | boolean;
  userId: number;
};

type FeedbackResponse = {
  data: {
    subscriptionFeedbacks: Array<FeedbackItem>;
    total: number;
    unreviewed: number;
  };
};

export const requestFeedback = async ({
  page,
  limit,
}: {
  page: number;
  limit: number;
}) => {
  return (
    await axiosInstance.get<FeedbackResponse>(`admin/subfeedback`, {
      params: { page, limit },
      baseURL: BASE_URL_V2,
    })
  ).data.data;
};

export const requestViewFeedback = async ({ ids }: { ids: number[] }) => {
  return (
    await axiosInstance.post<FeedbackResponse>(
      `admin/subfeedback`,
      { ids },
      {
        baseURL: BASE_URL_V2,
      }
    )
  ).data.data;
};
