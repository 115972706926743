import { AppleIcon, GoogleIcon, UserIcon, VoiceIcon } from "../../assets";
import { HiddenBlock } from "../../components";
import { IUser } from "../../models";
import {
  capitalizeFirstLetter,
  formatNumberToLocaleString,
} from "../../utils/methods";
import styles from "./styles.module.scss";
import dayjs from "dayjs";

type UserInfoProps = {
  user: IUser;
};

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const age = dayjs().diff(dayjs(user?.dateOfBirth), "year");

  return (
    <div className={styles.wrapper}>
      <div className={styles.userAvatar}>
        {user?.image ? (
          <img src={user.image} className={styles.avatar} alt="avatar" />
        ) : (
          <UserIcon />
        )}
      </div>
      <div className={styles.container}>
        <p className={styles.userName}>
          {user?.firstName} {user?.lastName} <span>({age})</span>
        </p>
        <div className={styles.emailContainer}>
          {user?.authData?.registrationType === "google" ? (
            <GoogleIcon />
          ) : user?.authData?.registrationType === "apple" ? (
            <AppleIcon />
          ) : null}
          <p className={styles.body1Title}>{user?.email}</p>
        </div>

        <p className={styles.body1Title}>
          Registered: {dayjs(user?.createdAt).format("DD MMM, YYYY")}
        </p>
        <p className={styles.body1Title}>
          Last completed day:{" "}
          {user?.last?.lastCompletedDay
            ? dayjs(user?.last?.lastCompletedDay).format("DD MMM, YYYY")
            : "-"}
        </p>

        <p className={styles.body1Title}>
          Subscription plan:{" "}
          {user?.subscriptionStatus
            ? `${capitalizeFirstLetter(user?.subscriptionStatus || "")} - `
            : ""}
          {user?.subscriptionType?.includes("monthly")
            ? "1 Month"
            : user?.subscriptionType?.includes("3months")
            ? "3 Months"
            : user?.subscriptionType?.includes("6months")
            ? "6 Months"
            : "-"}
        </p>
        <p className={styles.body1Title}>
          Referral: {user?.referral ? user?.referral : "-"}
        </p>
      </div>
      <div className={styles.personalInfoContainer}>
        <div className={styles.rowContainer}>
          <HiddenBlock
            title="Date of Birth"
            value={dayjs(user?.dateOfBirth).format("DD MMM, YYYY")}
            width={130}
          />
          <HiddenBlock
            title="Height"
            value={
              user?.height?.unit === "cm"
                ? `${formatNumberToLocaleString(user?.height?.cm || 0, 1)} cm`
                : `${formatNumberToLocaleString(
                    user?.height?.feet || 0,
                    1
                  )} ft ${formatNumberToLocaleString(
                    user?.height?.inches || 0,
                    1
                  )} in`
            }
            width={100}
            isHiddenDefault={false}
          />

          <HiddenBlock title="Sex" value={user?.gender} width={95} />
          <HiddenBlock
            title="Phone"
            value={user?.phone || "Empty"}
            width={144}
          />
        </div>
        <div className={styles.coachCallsInfo}>
          <VoiceIcon />
          <div className={styles.infoBlock}>
            <p className={styles.title}>Onboarding Call</p>
            <p className={styles.value}>
              {capitalizeFirstLetter(user?.onboardingCall || "-")}
            </p>
          </div>
          <div className={styles.infoBlock}>
            <p className={styles.title}>Scheduled</p>
            <p className={styles.value}>
              {user?.onboardingCall !== "available" &&
              user?.onboardingCallScheduledAt
                ? dayjs
                    .utc(user?.onboardingCallScheduledAt)
                    ?.format("DD MMM, YYYY")
                : "not scheduled"}
            </p>
          </div>
          <div className={styles.infoBlock}>
            <p className={styles.title}>Available Calls</p>
            <p className={styles.value}>{user?.availableCalls}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
