import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { PaginationButtons } from "../../../components";
import clsx from "clsx";
import { PulseLoader } from "react-spinners";
import { useQuery } from "@tanstack/react-query";
import {
  requestFeedback,
  requestViewFeedback,
} from "../../../services/feedback.service";
import FeedbackItem from "../../../components/FeedbackItem";
import { useMutation, useQueryClient } from "@tanstack/react-query";
const Feedback: FC = (): JSX.Element => {
  const [page, setPage] = useState(1);

  const { data, isPending } = useQuery({
    queryKey: ["feedback", page],
    queryFn: async () => {
      return await requestFeedback({
        page: page - 1,
        limit: 20,
      });
    },
  });

  const pageCount = data ? Math.ceil(data?.total / 20) : 0;

  const queryClient = useQueryClient();
  const { mutate: viewFeedback } = useMutation({
    mutationFn: requestViewFeedback,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["feedback"] });
    },
  });

  useEffect(() => {
    const notReadIds = data?.subscriptionFeedbacks
      .filter((item) => !item.reviewed)
      .map((item) => item.id);
    if (notReadIds?.length) {
      viewFeedback({ ids: notReadIds });
    }
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h4 className={styles.title}>Feedback</h4>
        <div className={styles.headerAddButton}></div>
      </header>

      <section className={styles.contentContainer}>
        <div className={styles.sectionHeader}>
          <div className={clsx(styles.reporterBlock)}>
            <p>User</p>{" "}
          </div>
          <div className={clsx(styles.typeBlock)}>
            <p>Sentiment</p>{" "}
          </div>
          <div className={clsx(styles.categoryBlock)}>
            <p>Feedback</p>{" "}
          </div>
          <div className={clsx(styles.reportedOnBlock)}>
            <p>App Version</p>{" "}
          </div>
          <div className={clsx(styles.dateBlock)}>
            <p>Feedback date</p>{" "}
          </div>
        </div>

        <div className={styles.listContainer}>
          {isPending ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            data?.subscriptionFeedbacks.map((item, index) => (
              <FeedbackItem key={index} item={item} />
            ))
          )}
        </div>
        <PaginationButtons
          pagesCount={pageCount}
          page={page}
          onPress={(index) => setPage(index)}
        />
      </section>
    </div>
  );
};

export default Feedback;
