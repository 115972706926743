import { FC } from "react";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";

import ReportType from "../ReportType";
import { format } from "date-fns";
import { type FeedbackItem as FeedbackItemType } from "../../services/feedback.service";

interface Props {
  item: FeedbackItemType;
}

const FeedbackItem: FC<Props> = ({ item }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.avatarWrapper}>
        <img
          src={item?.image ? item?.image : PlaceholderAvatar}
          alt="avatar"
          className={styles.avatar}
        />
        <div>
          <p className={styles.name}>{`${item?.firstName}`}</p>
          <p className={styles.email}>{`${item?.email}`}</p>
        </div>
      </div>
      <div className={styles.typeWrapper}>
        <ReportType
          type={
            item?.rating === "1"
              ? "negative"
              : item?.rating === "2"
              ? "neutral"
              : "positive"
          }
        />
      </div>
      <div className={styles.categoryWrapper}>
        <p>{item.area}</p>
        <p className={styles.subtitle}>{item.feedback}</p>
      </div>
      <div className={styles.reportedWrapper}>{item.appVersion}</div>
      <div className={styles.dateWrapper}>
        <p>
          {item?.createdAt &&
            format(new Date(item?.createdAt || ""), "LLL d, yyyy hh:mm a")}
        </p>
      </div>
    </div>
  );
};

export default FeedbackItem;
