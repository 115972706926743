import axiosInstance from "../apis";
import { AxiosResponse } from "axios";
import { BASE_URL_V2 } from "../constants";

export const getUsers = (
  value: string,
  page: number,
  limit: number,
  gender: string[],
  sortValue: string | null,
  order: number | null,
  subscriptionStatus?: Array<"trialing" | "renew_disabled" | "active">,
  subscriptionType?: Array<
    | "com.srama.subscription.6months"
    | "com.srama.subscription.3months"
    | "com.srama.subscription.monthly"
  >
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
    };
    if (gender?.length) {
      params.gender = gender?.join("|");
    }

    if (sortValue) {
      params.sortValue = sortValue;
    }
    if (value) {
      params.value = value;
    }

    if (subscriptionStatus?.length) {
      params.subscriptionStatus = subscriptionStatus?.join("|");
    }

    if (subscriptionType?.length) {
      params.subscriptionType = subscriptionType?.join("|");
    }

    if (order) {
      params.order = order === 1 ? "desc" : "asc";
    }
    axiosInstance
      .get("admin/user/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserById = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/user/${id}`, {
        baseURL: BASE_URL_V2,
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserLogs = (
  id: string,
  from: string,
  to: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      from,
      to,
    };
    axiosInstance
      .get(`admin/users/id/${id}/logs`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserDayStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/day`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserWeeklyStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/week`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserMonthStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/month`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserHalfYearStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/half`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserYearStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/year`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export interface StreakProgressRequestResponse {
  success: boolean;
  data: {
    statistics: Array<{
      date: string;
      goal: boolean;
      isDayCompleted: boolean;
      completionType: string;
      inStreak: boolean;
      isInGoal: boolean;
      out: number;
      expectedDeficit: number;
      deficit: number;
    }>;
    totalDeficit: number;
    totalIntake: number;
    totalCaloriesOut: number;
    streak: number;
    consistency: number;
  };
}

export const getUserConsistencyStreak = async (id: number, date: string) => {
  let params = {
    date,
  };

  return (
    await axiosInstance.get<StreakProgressRequestResponse>(
      `admin/user/${id}/progress/streak`,
      {
        params,
        baseURL: BASE_URL_V2,
      }
    )
  ).data.data;
};

export const deleteUser = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/user/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const blockUser = (
  userId: number,
  reason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      userId,
      reason,
    };
    axiosInstance
      .post("admin/user/block", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const unBlockUser = (userId: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      userId,
    };
    axiosInstance
      .post("admin/user/unblock", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export type MacroValue = {
  percentage: number;
  calories: number;
  grams: number;
};

export interface GoalRequestResponse {
  success: true;
  data: {
    dailyDeficit: number;
    weeklyDeficit: number;
    weightPerWeek: number;
    bmr: number;
    tdee: number;
    hasCustomMacros: boolean;
    recommendedWeeklyIntake: number;
    recommendedDailyIntake: number;
    daily: {
      intake: number;
      proteins: MacroValue;
      carbs: MacroValue;
      fats: MacroValue;
    };
    weekly: {
      intake: number;
      proteins: MacroValue;
      carbs: MacroValue;
      fats: MacroValue;
      alcohol: MacroValue;
    };
  };
}

export const getUserCalorieGoal = async (id: number) => {
  return (
    await axiosInstance.get<GoalRequestResponse>(`admin/user/${id}/goal`, {
      baseURL: BASE_URL_V2,
    })
  ).data.data;
};

export interface MealIntervalSection {
  sectionId: number;
  logSectionId: number;
  name: string;
  color: string;
  isDefault: boolean;
  position: number;
  calories: number;
  proteins: number;
  fats: number;
  carbs: number;
  fiber: number;
  alco: number;
  pe: number;
  foods: Food[];
}

export type FoodType = "Recipe" | "Ingredient" | "Estimate";

export type Food = {
  logId: number;
  logSectionId: number;
  logFoodId: number;
  foodId: number;
  foodType: FoodType;
  foodAuthor: "User" | "Admin";
  count: number;
  isLogged: boolean;
  ingredientUnit: string;
  name: string;
  description: string;
  image: string;
  brand: string;
  category: string;
  calories: number;
  proteins: number;
  fats: number;
  carbs: number;
  fiber: number;
  alco: number;
  pe: number;
};

export interface LogActivity {
  type: string;
  calories: number;
  minutes: number;
  tempo: "low" | "medium" | "high";
  isTracker: boolean;
  logTrackerId: null;
  isLogged: boolean;
  logActivityId: number;
}

export interface MealIntervalLog {
  id: number;
  date: string;
  weekday: number;
  isDayCompleted: boolean;
  isCompletionConfirmed: boolean;
  completionType: "Manual" | "Average";
  weight: number;
  trackerType: null | string;
  isLoggedWeight: boolean;
  userGoalId: number | null;
  hasCustomCalories: boolean;
  averageCaloriesUpToDate: number;
  weightLossMode: "Easy" | "Sustainable" | "Hard";
  manualDataUsed: boolean;
  loggedValues: {
    BMR: number;
    NEAT: number;
    alco: number;
    calories: number;
    carbs: number;
    fats: number;
    fiber: number;
    pe: number;
    proteins: number;
    deficit: number;
    activityCalories: number;
  };
  plannedValues: {
    alco: number;
    calories: number;
    carbs: number;
    fats: number;
    fiber: number;
    pe: number;
    proteins: number;
    deficit: number;
    activityCalories: number;
  };
  total: {
    alco: number;
    calories: number;
    carbs: number;
    fats: number;
    fiber: number;
    pe: number;
    proteins: number;
    deficit: number;
    activityCalories: number;
  };
  expected: {
    TDEE: number;
    BMR: number;
    NEAT: number;
    EAT: number;
    intake: number;
    calorieDeficitPerDay: number;
    proteins: {
      percentage: number;
      calories: number;
      grams: number;
    };
    carbs: {
      percentage: number;
      calories: number;
      grams: number;
    };
    fats: {
      percentage: number;
      calories: number;
      grams: number;
    };
    pe: number;
    alco: number;
  };
  activities: LogActivity[];
  sections: MealIntervalSection[];
}

export interface MealIntervalRequestResponse {
  success: number;
  data: {
    logs: MealIntervalLog[];
  };
}

export const getMealIntervalLogs = async (
  id: number,
  from: string,
  to: string
) => {
  return (
    await axiosInstance.get<MealIntervalRequestResponse>(
      `admin/user/${id}/logs`,
      { params: { from, to }, baseURL: BASE_URL_V2 }
    )
  ).data.data.logs;
};

export interface DailyProgress {
  logId: number;
  date: string;
  intake: number;
  BMR: number;
  NEAT: number;
  activityCalories: number;
  weight: number;
  isWeightLogged: boolean;
  expectedDeficit: number;
  deficit: number;
  superDeficit: number;
  userGoalId: number | null;
  isDayCompleted: boolean;
  loggedValues: {
    fats: number;
    carbs: number;
    proteins: number;
    fiber: number;
    alco: number;
    pe: number;
  };
}
export interface DailyProgressRequestResponse {
  data: {
    progress: DailyProgress[];
    accumulated: Array<{
      userGoalId: number | null;
      logId: number;
      date: string;
      intake: number;
      BMR: number;
      NEAT: number;
      activityCalories: number;
      weight: number;
      isWeightLogged: boolean;
      expectedDeficit: number;
      deficit: number;
      superDeficit: number;
      isDayCompleted: boolean;
    }>;
    deficitSum: number;
    deficitAvg: number;
    changeAvg: number;
    changeSum: number;
  };
}

export const getDailyProgress = async (
  id: number,
  from: string,
  to: string
) => {
  return (
    await axiosInstance.get<DailyProgressRequestResponse>(
      `admin/user/${id}/progress/day`,
      { params: { from, to }, baseURL: BASE_URL_V2 }
    )
  ).data.data;
};

export const getUserCalorieBurn = async (
  id: number,
  startDate: string,
  endDate: string
) => {
  return (
    await axiosInstance.get(`admin/user/${id}/calorie-burn`, {
      params: { startDate, endDate },
      baseURL: BASE_URL_V2,
    })
  ).data;
};

export const generateUserLogs = async (id: number) => {
  return (
    await axiosInstance.post(
      `admin/user/${id}/logs`,
      {},
      {
        baseURL: BASE_URL_V2,
      }
    )
  ).data;
};
