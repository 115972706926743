import React, { FC } from "react";
import styles from "./styles.module.scss";
import { ArrowSmallDownIcon, UserIcon } from "../../assets";
import { IProps } from "./types";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../utils/methods";

const UserItem: FC<IProps> = ({ item }): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/users/${item?.id}`)}
      className={styles.wrapper}
    >
      <div className={styles.nameBlock}>
        <div className={styles.avatarWrapper}>
          {item?.image ? (
            <img src={item?.image} className={styles.avatar} />
          ) : (
            <UserIcon />
          )}
        </div>
        <span className={styles.name}>
          {item?.firstName} {item?.lastName}
        </span>
      </div>
      <div className={styles.genderBlock}>
        <span className={styles.gender}>{item?.gender}</span>
      </div>

      <div className={styles.subscriptionBlock}>
        <span className={styles.subscription}>
          {item?.subscriptionType === "com.srama.subscription.6months"
            ? "6 Months"
            : item?.subscriptionType === "com.srama.subscription.3months"
            ? "3 Months"
            : item?.subscriptionType === "com.srama.subscription.monthly"
            ? "1 Month"
            : "-"}
        </span>
      </div>
      <div className={styles.subscriptionBlock}>
        <span className={styles.subscription}>
          {item?.subscriptionStatus === "expired"
            ? "Expired"
            : item?.subscriptionStatus === "trialing"
            ? "Free trial"
            : item?.subscriptionStatus === "renew_disabled"
            ? "Cancelled"
            : item?.subscriptionStatus === "active"
            ? "Active"
            : "-"}
        </span>
      </div>

      <div className={styles.deficitBlock}>
        <span className={styles.deficit}>{item?.email}</span>
      </div>

      <div className={styles.dateBlock}>
        <span className={styles.date}>
          {dayjs.utc(item?.onboardingAt).format("MMM DD, YYYY")}
        </span>
        <div className={styles.arrowButton}>
          <ArrowSmallDownIcon />
        </div>
      </div>
    </div>
  );
};

export default UserItem;
