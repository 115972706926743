import { CalendarIcon } from "../../assets";
import { formatNumberToLocaleString } from "../../utils/methods";
import LoggedContainer from "../LoggedContainer";
import styles from "./styles.module.scss";

type WeightSectionProps = {
  weight: number;
  weightUnit: "kg" | "lbs";
};

const WeightSection: React.FC<WeightSectionProps> = ({
  weight,
  weightUnit,
}) => {
  return (
    <div className={styles.wrapper}>
      <h6>Weight</h6>
      <LoggedContainer status="logged" order="single">
        <div className={styles.contentContainer}>
          <div className={styles.iconContainer}>
            <CalendarIcon />
          </div>
          <p>
            {formatNumberToLocaleString(weight, 0)} {weightUnit}
          </p>
        </div>
      </LoggedContainer>
    </div>
  );
};

export default WeightSection;
