import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Progress } from "../../components";
import styles from "./styles.module.scss";
import { TrophyIcon } from "../../assets";
import { IUser } from "../../models";
import dayjs from "dayjs";
import { formatNumberToLocaleString, getPercentage } from "../../utils/methods";
import { getTotalProgress } from "../../services/statistics.service";

type UserFatLossProps = {
  user: IUser;
};

const UserFatLoss: React.FC<UserFatLossProps> = ({ user }) => {
  const [activeGoal, setActiveGoal] = useState<
    "Fat Loss" | "Accumulated Deficit"
  >("Fat Loss");

  const [containerWidth, setContainerWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const [totalProgress, setTotalProgress] = useState<{
    expectedDeficitSum: number;
    deficitSum: null | number;
  }>({
    expectedDeficitSum: 0,
    deficitSum: 0,
  });

  const fetchTotalProgress = async () => {
    const { data } = await getTotalProgress(user?.id);
    if (data) {
      setTotalProgress(data);
    }
  };

  useEffect(() => {
    setContainerWidth(containerRef.current?.clientWidth || 0);
  }, [containerRef]);

  useEffect(() => {
    fetchTotalProgress();
  }, [user]);

  const startValue =
    activeGoal === "Fat Loss" ? user?.highestWeightLog || 0 : 0;
  const goalValue =
    activeGoal === "Fat Loss"
      ? user?.weight?.goal
      : totalProgress.expectedDeficitSum;

  const currentValue =
    activeGoal === "Fat Loss"
      ? user?.weight?.current
      : totalProgress.deficitSum || 0;

  const progress = useMemo(() => {
    const differentInStartAndGoal = startValue - goalValue;
    const differentInCurrentAndGoal = currentValue - goalValue;

    const value =
      activeGoal === "Fat Loss"
        ? getPercentage(
            differentInStartAndGoal - differentInCurrentAndGoal,
            differentInStartAndGoal
          )
        : getPercentage(currentValue, goalValue);

    if (startValue === currentValue) {
      return 0;
    } else if (value > 100) {
      return 100;
    } else if (value < 0) {
      return 0;
    } else {
      return value;
    }
  }, [startValue, goalValue, currentValue, containerWidth]);

  const startDate = useMemo(() => {
    return user?.goals?.find((item) => item.isOnboarding)?.goalStartsAt;
  }, [user]);

  const differenceBetweenLastCompletedDayInDays = user?.last?.lastCompletedDay
    ? dayjs
        .utc(dayjs.utc(user?.last?.lastCompletedDay).add(1, "day"))
        .diff(dayjs.utc(user?.onboardingAt), "days")
    : 0;

  const differenceBetweenLastCompletedDayInWeeks = user?.last?.lastCompletedDay
    ? +dayjs
        .utc(dayjs.utc(user?.last?.lastCompletedDay).add(1, "day"))
        .diff(dayjs.utc(user?.onboardingAt), "weeks", true)
        .toFixed(1)
    : 0;

  return (
    <div ref={containerRef} className={styles.wrapper}>
      <p className={styles.title}>Fat Loss</p>

      <div className={styles.container}>
        <div className={styles.rowContainer}>
          <Button
            onClick={() => setActiveGoal("Fat Loss")}
            size="x-small"
            title="Fat Loss Goal"
            styleType={activeGoal === "Fat Loss" ? "filled" : "inactive"}
          />
          <Button
            onClick={() => setActiveGoal("Accumulated Deficit")}
            size="x-small"
            title="Accumulated Deficit"
            styleType={
              activeGoal === "Accumulated Deficit" ? "filled" : "inactive"
            }
          />
        </div>
        <div className={styles.progressContainer}>
          <div>
            <p className={styles.title}>
              {activeGoal === "Fat Loss"
                ? "Current Weight"
                : "Accumulated Deficit"}
            </p>
            <p className={styles.weight}>
              {formatNumberToLocaleString(currentValue, 1)}{" "}
              <span>
                {activeGoal === "Fat Loss" ? user?.weight?.unit : "cal"}
              </span>
            </p>
            <p className={styles.currentValueDate}>
              {activeGoal === "Fat Loss"
                ? dayjs
                    .utc(user?.last?.lastUpdatedWeightDay)
                    ?.format("DD MMM, YYYY")
                : differenceBetweenLastCompletedDayInDays >= 7
                ? `${differenceBetweenLastCompletedDayInWeeks} ${
                    differenceBetweenLastCompletedDayInWeeks === 1
                      ? "week"
                      : "weeks"
                  }`
                : `${differenceBetweenLastCompletedDayInDays} ${
                    differenceBetweenLastCompletedDayInDays === 1
                      ? "day"
                      : "days"
                  }`}
            </p>
          </div>

          <div className={styles.rowProgressContainer}>
            <Progress
              height={25}
              progress={progress / 100}
              backgroundColor="rgba(255, 168, 0, 0.2)"
              tintColor="rgba(255, 168, 0, 1)"
              startWeight={startValue}
              goalWeight={goalValue}
              showGoalSteps={activeGoal === "Fat Loss"}
              user={user}
            />

            <TrophyIcon />
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.valueContainer}>
              <p className={styles.weight}>
                {formatNumberToLocaleString(startValue, 1)}{" "}
                <span>
                  {activeGoal === "Fat Loss" ? user?.weight?.unit : "cal"}
                </span>
              </p>
              <p className={styles.date}>
                {dayjs.utc(user?.highestWeightLogDate).format("MMM DD, YYYY")}
              </p>
              {!dayjs
                .utc(startDate)
                .isSame(dayjs.utc(user?.highestWeightLogDate), "day") &&
              !dayjs.utc(startDate).isAfter(dayjs().utc(true), "day") ? (
                <p className={styles.date}>
                  Originally {dayjs.utc(startDate).format("MMM DD, YYYY")}
                </p>
              ) : null}
            </div>
            <div className={styles.valueContainer}>
              <p className={styles.weight}>
                {formatNumberToLocaleString(goalValue, 1)}{" "}
                <span>
                  {activeGoal === "Fat Loss" ? user?.weight?.unit : "cal"}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFatLoss;
