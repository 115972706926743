import { rejects } from "assert";
import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getTags = (search: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      value: search,
      limit: 1000,
    };
    axiosInstance
      .get("admin/tag", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createTags = (name: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      value: name,
    };
    axiosInstance
      .post("admin/tag", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateTags = (
  oldValue: string,
  newValue: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      newValue,
      oldValue,
    };

    axiosInstance
      .put("admin/tag", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteTags = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/tag/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getRecipes = (
  page: number,
  limit: number,
  search: string,
  categories: string[],
  tags: string[],
  sortValue?: "name" | "category" | null,
  order?: -1 | 1 | null,
  abortController?: AbortController
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
      value: search,
    };

    if (categories.length > 0) {
      params.categories = categories.join("|");
    }

    if (tags.length > 0) {
      params.tags = tags.join("|");
    }

    if (sortValue) {
      params.sortValue = sortValue;
    }

    if (order) {
      params.order = order === -1 ? "asc" : "desc";
    }

    axiosInstance
      .get("admin/recipe", { params, signal: abortController?.signal })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getRecipeById = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/recipe/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getUserRecipeById = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/recipe/user/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

interface ICreateRecipeIngredient {
  ingredientId: number;
  unit: string;
  count: number;
}

export const getLinkForUploadRecipeImage = (
  fileName: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      fileName,
      type: "recipes",
    };
    axiosInstance
      .post("image/admin", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createRecipe = (
  name: string,
  tags: number[],
  category: number,
  method: string[],
  ingredients: ICreateRecipeIngredient[],
  description: string,
  image: string,
  numberOfServes: number,
  gramsPerServe: number,
  gramsPerServeUnit: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let body: any = {
      name,
      tags,
      category,
      ingredients,
      numberOfServes,
      method,
    };
    if (description) {
      body.description = description;
    }

    if (image) {
      body.image = image;
    }
    if (gramsPerServe) {
      body.perServe = gramsPerServe;
    }
    if (gramsPerServeUnit) {
      body.perServeUnit = gramsPerServeUnit;
    }
    axiosInstance
      .post("admin/recipe", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateRecipe = (
  name: string,
  tags: number[],
  category: number,
  method: string[],
  ingredients: ICreateRecipeIngredient[],
  description: string,
  image: string,
  recipeId: number,
  numberOfServes: number,
  gramsPerServe: number,
  gramsPerServeUnit: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let body: any = {
      name,
      tags,
      category,
      ingredients,
      recipeId,
      image,
      numberOfServes,
      method,
    };
    if (description) {
      body.description = description;
    }

    if (gramsPerServe) {
      body.perServe = gramsPerServe;
    }
    if (gramsPerServeUnit) {
      body.perServeUnit = gramsPerServeUnit;
    }

    axiosInstance
      .put("admin/recipe", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteRecipe = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/recipe/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
