import { useMemo } from "react";
import { IUser } from "../../models";
import styles from "./styles.module.scss";
import {
  getUserCalorieGoal,
  MealIntervalLog,
} from "../../services/users.service";
import { formatNumberToLocaleString } from "../../utils/methods";
import dayjs from "dayjs";
import {
  AlcoIcon,
  CarbsIcon,
  FatsIcon,
  MeatIcon,
  ProteinsIcon,
} from "../../assets";
import { COLORS } from "../../constants";
import { useQuery } from "@tanstack/react-query";

type CalorieGoalProps = {
  user: IUser;
  mealLog?: MealIntervalLog;
  selectedWeekMealInterval?: MealIntervalLog[];
};

const CalorieGoal: React.FC<CalorieGoalProps> = ({
  user,
  mealLog,
  selectedWeekMealInterval,
}) => {
  const { data } = useQuery({
    queryKey: ["user-calorie-goal", user?.id],
    queryFn: () => {
      return getUserCalorieGoal(user?.id);
    },
  });

  const reachedProtein = mealLog?.total?.proteins || 0;
  const goalProtein = mealLog?.expected?.proteins.grams || 0;
  const ratioProtein = mealLog?.expected?.proteins.percentage || 0;
  const dailyProtein = mealLog?.expected?.proteins.calories || 0;
  const weeklyProtein = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, cur) => {
        return acc + cur.expected.proteins.calories;
      }, 0) || 0
    );
  }, [selectedWeekMealInterval]);

  const reachedFats = mealLog?.total?.fats || 0;
  const goalFats = mealLog?.expected?.fats.grams || 0;
  const ratioFats = mealLog?.expected?.fats.percentage || 0;
  const dailyFats = mealLog?.expected?.fats.calories || 0;

  const weeklyFats = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, cur) => {
        return acc + cur.expected.fats.calories;
      }, 0) || 0
    );
  }, [selectedWeekMealInterval]);

  const reachedCarbs = mealLog?.total?.carbs || 0;
  const goalCarbs = mealLog?.expected?.carbs.grams || 0;
  const ratioCarbs = mealLog?.expected?.carbs.percentage || 0;
  const dailyCarbs = mealLog?.expected?.carbs.calories || 0;

  const weeklyCarbs = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, cur) => {
        return acc + cur.expected.carbs.calories;
      }, 0) || 0
    );
  }, [selectedWeekMealInterval]);

  const weeklyAlcohol = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, cur) => {
        return acc + cur.expected.alco;
      }, 0) || 0
    );
  }, [selectedWeekMealInterval]);

  const totalDailyCalories = dailyProtein + dailyFats + dailyCarbs;

  const totalWeeklyCalories =
    weeklyProtein + weeklyFats + weeklyCarbs + weeklyAlcohol;

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Calorie Goal Breakdown</p>
      <p className={styles.updatedDate}>
        Updated:{" "}
        {dayjs
          .utc(user?.lastRecommendation?.date || user?.onboardingAt)
          .format("MM/DD/YYYY")}
      </p>
      <p className={styles.infoTitle}>
        Method:{" "}
        <span>{data?.hasCustomMacros ? "Custom" : user?.diet?.name}</span>
      </p>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div
            style={{ paddingLeft: "12px" }}
            className={styles.tableHeaderTitle}
          >
            Macros
          </div>
          <div className={styles.tableHeaderTitle}>Reached</div>
          <div className={styles.tableHeaderTitle}>Goal</div>
          <div className={styles.tableHeaderTitle}>Ratio</div>
          <div className={styles.tableHeaderTitle}>Daily</div>
          <div style={{ flex: 1.2 }} className={styles.tableHeaderTitle}>
            Weekly
          </div>
        </div>
        <div className={styles.tableRow}>
          <div
            style={{
              fontWeight: 400,
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "12px",
            }}
            className={styles.tableRowTitle}
          >
            <MeatIcon style={{ fill: COLORS.blue }} /> Proteins
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(reachedProtein, 0)} g
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(goalProtein, 0)} g
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(ratioProtein)}%
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(dailyProtein, 0)} cal
          </div>
          <div style={{ flex: 1.2 }} className={styles.tableRowTitle}>
            {formatNumberToLocaleString(weeklyProtein, 0)} cal
          </div>
        </div>
        <div className={styles.tableRow}>
          <div
            style={{
              fontWeight: 400,
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "12px",
            }}
            className={styles.tableRowTitle}
          >
            <FatsIcon style={{ fill: COLORS.orange }} /> Fats
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(reachedFats)} g
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(goalFats)} g
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(ratioFats)}%
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(dailyFats)} cal
          </div>
          <div style={{ flex: 1.2 }} className={styles.tableRowTitle}>
            {formatNumberToLocaleString(weeklyFats)} cal
          </div>
        </div>
        <div className={styles.tableRow}>
          <div
            style={{
              fontWeight: 400,
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "12px",
            }}
            className={styles.tableRowTitle}
          >
            <CarbsIcon style={{ fill: COLORS.orchid }} />
            Carbs
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(reachedCarbs)} g
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(goalCarbs)} g
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(ratioCarbs)}%
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(dailyCarbs)} cal
          </div>
          <div style={{ flex: 1.2 }} className={styles.tableRowTitle}>
            {formatNumberToLocaleString(weeklyCarbs)} cal
          </div>
        </div>
        <div className={styles.tableRow}>
          <div
            style={{
              fontWeight: 400,
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "12px",
            }}
            className={styles.tableRowTitle}
          >
            <AlcoIcon style={{ fill: COLORS.brown }} />
            Alcohol
          </div>
          <div className={styles.tableRowTitle}></div>
          <div className={styles.tableRowTitle}></div>
          <div className={styles.tableRowTitle}></div>
          <div className={styles.tableRowTitle}></div>
          <div style={{ flex: 1.2 }} className={styles.tableRowTitle}>
            {weeklyAlcohol} cal
          </div>
        </div>
        <div
          style={{ borderColor: "rgba(97, 42, 255, 1)" }}
          className={styles.tableRow}
        >
          <div className={styles.tableRowTitle}>Total</div>
          <div className={styles.tableRowTitle}></div>
          <div className={styles.tableRowTitle}></div>
          <div className={styles.tableRowTitle}></div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(totalDailyCalories)} cal
          </div>
          <div className={styles.tableRowTitle}>
            {formatNumberToLocaleString(totalWeeklyCalories)} cal
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalorieGoal;
