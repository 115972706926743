import React, { FC, useEffect, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDownIcon } from "../../assets";
import { CheckBox } from "..";
import clsx from "clsx";

const Genders = ["Male", "Female"];

const SubscriptionPlans = [
  { value: "com.srama.subscription.monthly", label: "1 Month" },
  { value: "com.srama.subscription.3months", label: "3 Months" },
  { value: "com.srama.subscription.6months", label: "6 Months" },
];

const SubscriptionStatuses = [
  { value: "trialing", label: "Free trial" },
  { value: "renew_disabled", label: "Cancelled" },
  { value: "active", label: "Active" },
  { value: "expired", label: "Expired" },
];

const UserFilter: FC<IProps> = ({
  setIsOpen,
  filterGender,
  setFilterGender,
  setFilterSubscriptionStatus,
  filterSubscriptionStatus,
  setFilterSubscriptionType,
  filterSubscriptionType,
}): JSX.Element => {
  const [isShowGenderList, setIsShowGenderList] = useState(false);
  const [isShowSubscriptionPlanList, setIsShowSubscriptionPlanList] =
    useState(false);
  const [isShowSubscriptionStatusList, setIsShowSubscriptionStatusList] =
    useState(false);

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0.5 }}
      animate={{ scale: 1, opacity: 1 }}
      className={styles.wrapper}
    >
      <h5 className={styles.title}>Filter</h5>
      <div className={styles.sectionDevider} />
      <div
        onClick={() => setIsShowGenderList((prev) => !prev)}
        className={styles.sectionHeader}
      >
        <span className={styles.sectionTitle}>Gender</span>
        <ArrowDownIcon
          className={clsx({
            [styles.arrowOpen]: isShowGenderList,
            [styles.arrowClosed]: !isShowGenderList,
          })}
        />
      </div>
      <AnimatePresence>
        {isShowGenderList && (
          <motion.div
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: 52, marginTop: 10 }}
            exit={{ height: 0, marginTop: 0 }}
            className={styles.listContainer}
          >
            {Genders.map((item, index) => {
              const isChecked = filterGender.includes(item);
              const onSelect = () => {
                if (isChecked) {
                  setFilterGender(filterGender.filter((el) => el !== item));
                } else {
                  setFilterGender([...filterGender, item]);
                }
              };

              return (
                <div key={index} className={styles.selectorItem}>
                  <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
                  <span
                    style={{ color: isChecked ? "#11142D" : "#808191" }}
                    className={styles.selectorItemText}
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.sectionDevider} />
      <div
        onClick={() => setIsShowSubscriptionPlanList((prev) => !prev)}
        className={styles.sectionHeader}
      >
        <span className={styles.sectionTitle}>Subscription Plan</span>
        <ArrowDownIcon
          className={clsx({
            [styles.arrowOpen]: isShowSubscriptionPlanList,
            [styles.arrowClosed]: !isShowSubscriptionPlanList,
          })}
        />
      </div>
      <AnimatePresence>
        {isShowSubscriptionPlanList && (
          <motion.div
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: 90, marginTop: 10 }}
            exit={{ height: 0, marginTop: 0 }}
            className={styles.listContainer}
          >
            {SubscriptionPlans.map((item, index) => {
              const isChecked = filterSubscriptionType.includes(item.value);
              const onSelect = () => {
                if (isChecked) {
                  setFilterSubscriptionType(
                    filterSubscriptionType.filter((el) => el !== item.value)
                  );
                } else {
                  setFilterSubscriptionType([
                    ...filterSubscriptionType,
                    item.value,
                  ]);
                }
              };

              return (
                <div key={index} className={styles.selectorItem}>
                  <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
                  <span
                    style={{ color: isChecked ? "#11142D" : "#808191" }}
                    className={styles.selectorItemText}
                  >
                    {item.label}
                  </span>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.sectionDevider} />
      <div
        onClick={() => setIsShowSubscriptionStatusList((prev) => !prev)}
        className={styles.sectionHeader}
      >
        <span className={styles.sectionTitle}>Subscription Status</span>
        <ArrowDownIcon
          className={clsx({
            [styles.arrowOpen]: isShowSubscriptionStatusList,
            [styles.arrowClosed]: !isShowSubscriptionStatusList,
          })}
        />
      </div>
      <AnimatePresence>
        {isShowSubscriptionStatusList && (
          <motion.div
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: 120, marginTop: 10 }}
            exit={{ height: 0, marginTop: 0 }}
            className={styles.listContainer}
          >
            {SubscriptionStatuses.map((item, index) => {
              const isChecked = filterSubscriptionStatus.includes(item.value);
              const onSelect = () => {
                if (isChecked) {
                  setFilterSubscriptionStatus(
                    filterSubscriptionStatus.filter((el) => el !== item.value)
                  );
                } else {
                  setFilterSubscriptionStatus([
                    ...filterSubscriptionStatus,
                    item.value,
                  ]);
                }
              };

              return (
                <div key={index} className={styles.selectorItem}>
                  <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
                  <span
                    style={{ color: isChecked ? "#11142D" : "#808191" }}
                    className={styles.selectorItemText}
                  >
                    {item.label}
                  </span>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.sectionDevider} />
      <div
        onClick={() => {
          setFilterGender([]);
        }}
        className={styles.resetButton}
      >
        <div>Reset all filters</div>
      </div>
    </motion.div>
  );
};

export default UserFilter;
