export interface Height {
  cm: string;
  feet: string;
  inches: string;
}

export type Gender = "Male" | "Female";

export type HeightUnit = "cm" | "feet";
export type WeightUnit = "kg" | "lbs";
export type WeightLossMode = "Easy" | "Sustainable" | "Hard";

export type Goal = {
  id: number;
  timezone: string;
  deficit: number;
  weight: number;
  startWeight: number;
  neatFactor: "string";
  createdAt: string;
  goalStartsAt: string;
  goalEndsAt: string;
  isOnboarding: boolean;
};

export enum EACTIVITY_LEVEL {
  SEDENTARY = "Sedentary",
  LIGHTLY_ACTIVE = "Light Activity",
  MODERATELY_ACTIVE = "Moderate Activity",
  VERY_ACTIVE = "Very Active",
  EXTRA_ACTIVE = "Extra Active",
}

export type AISettings = {
  ignoredCategories: string[];
  ignoredTags: string[];
  dislikesFood: Array<{ ingredient_id: string; name: string }>;
  preferredProteins: string[];
};

export type SkipReason = {
  id: number;
  userId: number;
  reason: string;
  from: string;
  to: string;
  createdAt: string;
};
export interface IUser {
  id: number;
  email: string;
  neatFactor: EACTIVITY_LEVEL;
  lastRecommendation?: null | { date: string };
  howOftenWearsTracker: null | string;
  image: null | string;
  subscriptionType: string;
  highestWeightLog: number;
  highestWeightLogDate: string;
  onboardingCallScheduledAt: null | string;
  firstName: string;
  lastName: string;
  anotherTrackers: string[];
  gender: Gender;
  hasBadBMR: boolean;
  referral: string | null;
  availableCalls: number;
  weight: {
    unit: WeightUnit;
    current: number;
    goal: number;
    start: number;
    completed: number;
    week: number;
  };
  height: {
    unit: HeightUnit;
    cm?: number;
    feet?: number;
    inches?: number;
  };
  isDiaryTutorialVisible: boolean;
  BMR: number;
  NEAT: number;
  weeksToReachGoal: number;
  subscriptionStatus: string | null;
  dateOfBirth: string;
  age: number;
  onboardingCall?: "available" | "used";
  // activities: Activity[];
  // neatFactor: EACTIVITY_LEVEL;
  northStar: string;
  weightLossMode: WeightLossMode;
  diet: {
    name: string;
    proteins: number;
    carbs: number;
    fats: number;
  };
  last: {
    lastUpdatedWeightDay: string | null;
    lastCompletedDay: string | null;
    lastGeneratedLog: {
      logId: number;
      date: string;
    };
  };
  phone?: string;

  // alcohol: Alcohol[];
  lifeTimeValue: number;
  customMacros: null;
  allowNotifications: boolean;
  allowAppNotification: boolean;
  aiSettings: AISettings;
  onboardingAt: string;
  startDate: string;
  timezone: string;
  weeklyDeficitAvg: number;
  weeklyActivity: number;
  tracker?: string;
  createdAt: string;
  trackerType?: string;
  isFeedbackSentDay12?: boolean;
  isFeedbackSentDay24?: boolean;
  isFeedbackSentDay36?: boolean;
  feedbackModalShownDay12?: boolean;
  feedbackModalShownDay24?: boolean;
  feedbackModalShownDay36?: boolean;
  authData: {
    registrationType: "email" | "google" | "apple";
    isActivated: boolean;
    isBlocked: boolean;
    blockReason: null | string;
  };
  goals: Goal[];
  skipReasons: SkipReason[];
  familiarityLevel: string;
  supportContacted: number | null;
  initialSubscriptionPlan: string | null;
  subscriptionStartDate: string;
  subscriptionRenewalDate: string;
  subscriptionCancellationDate: string | null;
  totalWorkoutsLogged: number;
}
