import clsx from "clsx";
import { FC } from "react";
import styles from "./styles.module.scss";

interface Props {
  type: "positive" | "negative" | "neutral" | "problem" | "user";
}

const ReportType: FC<Props> = ({ type }): JSX.Element => {
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.problemWrapper]: type === "problem",
        [styles.userWrapper]: type === "user",
        [styles.positiveWrapper]: type === "positive",
        [styles.negativeWrapper]: type === "negative",
        [styles.neutralWrapper]: type === "neutral",
      })}
    >
      <span>
        {type === "negative"
          ? "Negative"
          : type === "neutral"
          ? "Neutral"
          : type === "positive"
          ? "Positive"
          : type === "user"
          ? "User complaint"
          : "Problem report"}
      </span>
    </div>
  );
};

export default ReportType;
