import dayjs from "dayjs";
import { TriangleIcon } from "../../assets";
import { formatNumberToLocaleString, kgToLbs } from "../../utils/methods";
import styles from "./styles.module.scss";
import { IUser } from "../../models";
import { useMemo, useState } from "react";

type ProgressProps = {
  progress: number;
  tintColor?: string;
  backgroundColor?: string;
  height?: number;
  user?: IUser;
  startWeight?: number;
  goalWeight?: number;
  showGoalSteps?: boolean;
};

type GoalStepProps = {
  position: number;
  startWeight: number;
  goalWeight: number;
  weightUnit: string;
  date: string;
};

const GoalStep: React.FC<GoalStepProps> = ({
  position,
  startWeight,
  goalWeight,
  weightUnit,
  date,
}) => {
  return (
    <div style={{ left: `${position}%` }} className={styles.goalStep}>
      <div className={styles.tooltip}>
        <p className={styles.title}>New Goal</p>
        <p className={styles.value}>
          {formatNumberToLocaleString(startWeight, 0)} {weightUnit} →{" "}
          {formatNumberToLocaleString(goalWeight, 0)} {weightUnit}
        </p>
        <p className={styles.date}>{dayjs.utc(date).format("MMM DD, YYYY")}</p>
        <TriangleIcon />
      </div>
      <div className={styles.goalStepInner}></div>
    </div>
  );
};

function getPointPosition(
  currentWeight: number,
  minWeight: number,
  maxWeight: number
) {
  return ((currentWeight - minWeight) / (maxWeight - minWeight)) * 100;
}

const Progress: React.FC<ProgressProps> = ({
  progress = 0,
  startWeight,
  goalWeight,
  backgroundColor,
  height = 24,
  tintColor,
  showGoalSteps,
  user,
}) => {
  const userGoals = useMemo(() => {
    return user?.goals
      ?.filter((goal) => !goal?.isOnboarding)
      .map((goal) => {
        const startWeight =
          user?.weight?.unit === "kg"
            ? goal?.startWeight
            : +kgToLbs(goal?.startWeight);

        const goalWeight =
          user?.weight?.unit === "kg" ? goal?.weight : +kgToLbs(goal?.weight);

        return {
          startWeight,
          goalWeight,
          date: goal?.goalStartsAt,
          weightUnit: user?.weight?.unit,
        };
      });
  }, [user]);

  return (
    <div style={{ flex: 1, position: "relative" }}>
      {showGoalSteps &&
        startWeight &&
        goalWeight &&
        userGoals?.map((goal, index) => {
          const position = getPointPosition(
            goal.startWeight,
            startWeight,
            goalWeight
          );

          return (
            <GoalStep
              key={index}
              position={position}
              startWeight={goal.startWeight}
              goalWeight={goal.goalWeight}
              weightUnit={goal.weightUnit}
              date={goal.date}
            />
          );
        })}
      <div
        style={{
          backgroundColor: backgroundColor ? backgroundColor : undefined,
          height,
        }}
        className={styles.wrapper}
      >
        <div
          style={{
            width: `${100 * progress}%`,
            backgroundColor: tintColor ? tintColor : undefined,
          }}
          className={styles.progress}
        ></div>
      </div>
    </div>
  );
};

export default Progress;
